@import "../variables";

.header {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 5rem;
  background-color: $color-primary;
  font-family: $font-primary;
  border-bottom: solid 2px $color-secondary;

  &__title {
    color: white;
    text-decoration: none !important;
  }
}
