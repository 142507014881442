@import "../variables";

.footer {
  background-color: $color-primary;
  color: white;
  padding: 5rem 2rem;
  font-size: $default-font-size;
  line-height: 2.5rem;
  font-family: $font-primary;
  border-top: solid 2px $color-secondary;


  ul {
    text-align: left;
    list-style: none;
    font-weight: 100;
    border-top: 1px solid $color-gray-light-1;
    border-bottom: 1px solid $color-gray-light-1;
    padding: 3rem 0;
  }

  p {
    text-align: right;
  }

  &__link {
    float: right;
    color: white;
    font-size: 3rem;
    margin: 1rem;
  }
}
