@import "../mixins";

.brain {
  height: 40vh;
  width: 100%;
  background-size: 80%, cover;
  background-position: center;
  background-repeat: no-repeat no-repeat;
  background-image: url("../../assets/hero-front.png"),
    url("../../assets/hero-background.jpg");
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.6);

  @include respond(tab-port) {
    background-size: 60%, cover;
  }

  @include respond(desktop) {
    background-size: 50%, cover;
  }
}

.symptoms {
  font-size: $default-font-size;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    display: inline-block;
    align-self: center;
    flex-wrap: wrap;
    columns: 2;
    margin: 3rem 0;

    li::before {
      content: "•";
      margin-right: 0.5rem;
    }
  }

  &__link {
    color: $color-primary;
    font-weight: 800;
  }
}

.proPic {
  align-self: center;
  width: 60% !important;
  margin: 3rem 0;
  height: auto !important;
  box-shadow: 0 0.5rem 1rem rgba(black, 0.6);
  border-radius: 3px;
}

.certifications {
  text-align: left;
  font-size: 1.6rem;
  padding: 0 5rem;
  text-indent: 0;
  li {
    line-height: 2.5rem;
  }
}

@include respond(tab-port) {
  .symptoms {
    ul {
      columns: 3;
      padding: 2rem 0rem;

      li {
        margin: 0 1rem;
      }
    }
  }

  .proPic {
    width: 40%;
  }

  .certifications {
    text-align: left;
    font-size: 1.6rem;
    padding: 0 5rem;
    text-indent: 0;
    li {
      line-height: 2.5rem;
    }
  }
}

@include respond(desktop) {
  .brain {
    height: 80vh;
  }

  // .about-card {
  //   flex-direction: row-reverse !important;
  //   .p {
  //     width: 50%;
  //   }
  // }

  .symptoms {
    ul {
      columns: 4;
    }
  }

  .proPic {
    width: 30% !important;
    margin: 0rem 0;
  }

  .about {
    padding: 2rem;
    margin: 3rem 0;
    background-color: white;
    box-shadow: 0 0.5rem 0.5rem rgba(black, 0.8);
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.home {
  *:not(.button):not(.heading-primary):not(h3):not(h2) {
    overflow-x: hidden;
  }
}
