.service-card {
    width: 80%;
    padding: 1rem;
    border: solid 2px $color-secondary;
    border-radius: 3px;
    // box-shadow: 0px 1px 22px -1px $color-gray-light-2;

    h2 {
        font-size: 2rem;
    }

    p {
        hyphens: auto;
        line-height: 1.5;
    }

    &-icon {
        font-size: 4rem;
        color: $color-primary;

        &--1 {
            margin-left: -2rem !important;
        }
    }

 

}

.card-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(tab-port) {
        margin: 1rem 0;
    }
    @include respond(desktop) {
        width: 25%;
        height: 10rem;
    }
   
}

.card-container {
    
}

.large-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}