@import "./variables";
@import "./mixins";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


html {
  font-size: 62.5%;
  font-family: $font-secondary;
  hyphens: auto;
  background-color: $color-primary;

  // @include respond(tab-land) {
  //   font-size: 90%;
  // }
  @include respond(tab-port) {
    font-size: 70%;
  }
  @include respond(desktop) {
    font-size: 75%;
    padding: 2rem;
    background-color: $color-gray-dark-2;

    body {
      border: solid $color-primary 2px;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.7);
      border-radius: 3px;
    }
  }
}

body {
  box-sizing: border-box;
  background-color: $color-gray-light-2;
  text-align: center;
  color: $color-dark-gray;

  p,
  .p {
    display: inline-block;
    line-height: 1.88;
    font-size: $default-font-size;
    padding: 0 3rem;
    text-indent: 2rem;
    text-align: left;

    @include respond(desktop) {
      margin: 3rem 0;
    }
  }

  .mobile {
    hr {
      height: 2px;
      border: solid 1px $color-secondary;
      margin-bottom: 2rem;
      width: 25%;
    }
  }

  .desktop {
    display: none;
  }

  .icon {
    font-size: 3rem;
    color: $color-secondary;
    position: relative;
    top: 0.5rem;
    right: 0.5rem;

    &-header {
      top: 0;
      text-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.7);
      margin: 0.5rem;
    }

    &-footer {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 600px) {
    .mobile {
      display: none !important;
    }
    .desktop {
      display: flex;
    }
  }
  .heading-primary {
    display: inline-block;
    padding: 1rem;
    letter-spacing: 0.3rem;
    font-size: $header-font-size;
    text-transform: uppercase;
    font-style: italic;
    background-image: linear-gradient(
      to right,
      $color-primary,
      $color-primary-light-2
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    hyphens: none;
    transition: all 0.4s;
    // -webkit-animation: skew 5s infinite 3s;
    // animation: skew 9s ease-out 0s infinite;

    &:hover {
      transform: scale(1.1);
      text-shadow: 0.5rem 1rem 2rem rgba(black, 0.6);
    }
  }

  .heading-secondary {
    font-style: italic;
    text-transform: uppercase;
  }

  .btn-text {
    margin: 3rem 0;font-size: $default-font-size;
    color: $color-primary;
    display: inline-flex;
    align-self: center;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 1rem;
    transition: all 0.2s;
    font-weight: 100;
    cursor:pointer;

    &:hover {
      background-color: $color-primary;
      color: white;
      box-shadow: 0 1rem 2rem rgba(black, 0.3);
      transform: translateY(-2px) scale(1.1);
      border-radius: 3px;
      svg {
        color: $color-secondary;
      }
    }

    &:active {
      box-shadow: 0 0.5rem 1rem rgba(black, 0.15);
      transform: translateY(0px);
    }
    
  }

  .link {
    font-size: 1.6rem;
    color: $color-primary;
    font-weight: 700;
  }

  .about-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    img {
      height: 30vh;
      width: auto;
      margin: 3rem;
      border-radius: 3px;
      border: solid 2px $color-secondary;
    }
  
  }

  ::selection {
    background-color: rgb(255, 187, 1);
    color: white;
  }

  .img-default {
    width: 80%;
    height: auto;
    margin: 2rem 0;
    align-self: center;

    @include respond(tab-port) {
      width: 60%;
    }
  }

  // @-webkit-keyframes skew {
  //   0% {
  //     font-size: $default-font-size;
  //   }

  //   40% {
  //     transform: scale(1.2) skewX(20rem);
  //     text-shadow: 0.5rem 1rem 2rem rgba(black, 0.6);
  //   }
  //   80% {
  //     font-size: $default-font-size;
  //   }
  //   100% {
  //     font-size: $default-font-size;
  //   }

  // }

  // @keyframes skew {
  //   0% {
  //     font-size: $default-font-size;
  //   }

  //   40% {
  //     transform: scale(1.2);
  //     text-shadow: 0.5rem 1rem 2rem rgba(black, 0.6);
  //   }
  //   80% {
  //     font-size: $default-font-size;
  //   }
  //   100% {
  //     font-size: $default-font-size;
  //   }

  // }

  .phone-link {
    color: white;
  }
}

@include respond(tab-port) {
  .p {
    border-left: solid 3px $color-secondary;
  }
}

.content {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 1rem 0;
    }

    p {
      text-align: left;
      padding: 2rem;
      hyphens: none;
      text-indent: 2rem;
    }
  }
}

.caption {
  font-style: italic;
  width: 80%;
  margin-top: -20px;
  color: $color-gray-dark-3;
  text-indent: 0 !important;
}

@include respond(tab-port) {
  .content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
      padding: 0 7rem;
    }
  }
}

@include respond(desktop) {
  .content {
    padding: 3rem 10rem;
  }
}

.video {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  overflow: hidden;

  @include respond(tab-port) {
    margin: 3rem 0;
  }
}

