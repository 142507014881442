.reference {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;

  h2 {
    align-self: left;
  }

  &-container {
    padding: 0rem 10rem;
  }

  .accordion {
    * {
      transition: all 0.3s !important;
    }
    margin: 3rem;
    width: 80%;
    background-color: $color-gray-light-1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 2s;

    &-icon::before {
      content: "+";
      color: $color-primary;
      font-size: 1.8rem;
    }

    &-heading {
      border-bottom: solid $color-primary 1px;
      padding: 1rem;
      cursor: pointer;
      text-align: left;
      color: $color-primary;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-body {
      display: none;
      height: 0;
      transition: all 2s;
    }

    &-open {
      .accordion-body {
        text-align: left;
        display: block;
        height: auto;
        padding: 2rem;

        P {
            padding: 0 3rem;
        }
      }

      .accordion-icon::before {
        content: "-";
        color: $color-primary;
        font-size: 2.2rem;
      }
    }
  }
}
