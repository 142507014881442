.about {
  flex-direction: column;
  height: 100%;
  margin: 0;

  &__picture {
    background-image: url("../../assets/profile_v2.jpg");
    height: 16rem;
    width: 16rem;
    background-size: cover;
    border-radius: 50%;
    margin: 2rem 0;
    border: solid 2px $color-secondary;
    box-shadow: 0 0.5rem 1rem rgba(black, 0.6);
    transition: all 0.4s;
      &:hover {
        transform: scale(1.1);
      }
  }


  p {
    margin: 2rem 0;
    text-align: left;
    text-indent: 2rem;
  }

  &__list {
    text-align: left;
    font-size: $default-font-size;
    line-height: 3rem;
    border-top: 1px solid $color-primary;
    border-bottom: 1px solid $color-primary;
    margin: 2rem auto;
    padding: 0 4rem;
    li {
      margin: 1rem 0;
    }
  }

  @include respond(tab-port) {

    &__picture {
      background-image: url("../../assets/profile.jpg");
      height: 20rem;
      width: 20rem;
      margin: 4rem 0;
      box-shadow: 0 1rem 3rem rgba(black, 0.6);
    }

    h3 {
      font-size: 2.5rem;
    }

    p {
      margin: 4rem 0;
    }

    &__list {
      margin: 4rem 2rem;
    }
  }

  @include respond(desktop) {
  }
}
