@import url("https://fonts.googleapis.com/css?family=EB+Garamond|Lato&display=swap");

$color-primary: #003663;
$color-primary-light: #0368bb;
$color-primary-light-2: #008cff;
$color-secondary: #fbbf2c;
$color-dark-gray: #777;
$color-gray-light-1: #f7f7f7;
$color-gray-light-2: #eee;
$color-gray-dark-2: #999;
$color-gray-dark-3: #333;
$font-primary: "EB Garamond", serif;
$font-secondary: "Lato", sans-serif;
$default-font-size: 1.6rem;
$header-font-size: 2rem;
